import styled from "styled-components"
import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const Mail = styled.input`
  padding: 0.2rem 0.5rem;
  outline: none;
  border: 2px solid black;
`
const Button = styled.input`
  padding: calc(0.2rem + 2px) calc(0.5rem + 2px);
  background-color: #f70707;
  border: none;
  color: white;
  margin-left: 1rem;
`
const Message = styled.span`
  max-width: 315px;
  display: block;
  position: absolute;
  margin-bottom: 1rem;
`
const Arrow = styled.span`
  width: 315px;
  text-align: center;
  display: block;
  position: absolute;
  transform: translateY(-1.4rem);
`
class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: null, message: null, arrow: null }
  }
  // 2. via `async/await`
  handleChange = event => {
    this.setState({ email: event.target.value })
  }
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        this.setState({
          message: data.msg,
          arrow: "▾",
        })
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  render() {
    return (
      <div>
        <form onSubmit={this._handleSubmit}>
          <Mail
            type="email"
            placeholder="yourmail@globart.at"
            onChange={this.handleChange}
          />
          <Button type="submit" value="Sign Up" />
        </form>
        <Arrow>{this.state.arrow}</Arrow>
        <Message>{this.state.message}</Message>
      </div>
    )
  }
}

export default Newsletter
