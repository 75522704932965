import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Social from "./social"
import Newsletter from "./newsletter"
const StyledFooterBig = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    color: red;
  }
`
const News = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16rem;
  align-items: flex-start;
  align-self: flex-end;
  transform: translateY(3.1rem);
  @media only screen and (max-width: 800px) {
    transform: translateY(0);
    margin-right: 0;
  }
`
const Menu = styled.div`
  display: flex;
  align-items: flex-end;
`

class Footer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { data } = this.props // add more props here as needed
		return (
      <>
        <StyledFooterBig>
        	<News>
        		<h4>Jetzt für den GLOBART Newsletter anmelden:</h4>
        		<Newsletter />
        	</News>
        	<StyledFooter>
        		<div>© {new Date().getFullYear()} GLOBART</div>
        		<Menu>
        			<Link to="/impressum/">Impressum</Link>
        			<Social />
        		</Menu>
        	</StyledFooter>
        </StyledFooterBig>
      </>
		)
	}
}

export default Footer
