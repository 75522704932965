/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title, image: metaImage }) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
	)

	const metaDescription = description || site.siteMetadata.description
	const image =
    metaImage && metaImage.src
    	? `${site.siteMetadata.siteUrl}${metaImage.src}`
    	: null
	return (
		<Helmet
			htmlAttributes={{
				lang: "en",
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: "description",
					content: metaDescription,
				},
				{
					property: "og:title",
					content: title,
				},
				{
					property: "og:description",
					content: metaDescription,
				},
				{
					name: "twitter:creator",
					content: site.siteMetadata.author,
				},
				{
					name: "twitter:title",
					content: title,
				},
				{
					name: "twitter:description",
					content: metaDescription,
				},
			]
				.concat(
					keywords.length > 0
						? {
							name: "keywords",
							content: keywords.join(", "),
						}
						: [],
					metaImage
						? [
							{
								property: "og:image",
								content: image,
							},
							{
								property: "og:image:width",
								content: metaImage.width,
							},
							{
								property: "og:image:height",
								content: metaImage.height,
							},
							{
								name: "twitter:card",
								content: "summary_large_image",
							},
						]
						: [
							{
								name: "twitter:card",
								content: "summary",
							},
						]
				)
				.concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: "en",
	meta: [],
	keywords: [],
	description: "",
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default SEO
