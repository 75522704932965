import styled from "styled-components"
import React from "react"
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"

const SocialContainer = styled.div`
  ul {
    display: flex;
    margin: 0;
  }
  ul li  {
    list-style: none;
    padding: 0rem 1rem;
    margin: none;
    margin: 0;
  }
  ul li a {
    color: black;
  }
  ul li:hover a {
    color: red;
  }
`

class Social extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<SocialContainer>
				<ul>
					<li>
						<a target="_blank" href="https://www.facebook.com/GLOBArtThinkTank">
							<FaFacebookF />
						</a>
					</li>
					<li>
						<a target="_blank" href="https://twitter.com/GLOBART_Academy">
							<FaTwitter />
						</a>
					</li>
					<li>
						<a target="_blank" href="https://www.instagram.com/globart.vienna/">
							<FaInstagram />
						</a>
					</li>
				</ul>
			</SocialContainer>
		)
	}
}

export default Social
