import React from "react"
import PropTypes from "prop-types"
import { FaFacebookF, FaTwitter, FaLinkedin, FaReddit, FaWhatsapp, FaShare} from "react-icons/fa"
import {
	FacebookShareButton,
	GooglePlusShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton
} from "react-share"

import "./share.css"

const Share = ({socialConfig, tags}) => (
  <>
	<div className='post-social'>
		<div className='button is-outlined is-rounded linkedin' >
			<LinkedinShareButton url={socialConfig.config.url} title={socialConfig.config.title} >
				<span className='icon'>
					<FaLinkedin />
				</span>
				<span className='text'>LinkedIn</span>
			</LinkedinShareButton>
		</div>
		<div className='button is-outlined is-rounded reddit'>
			<RedditShareButton url={socialConfig.config.url}  title={socialConfig.config.title} >
				<span className='icon'>
					<FaReddit />
				</span>
				<span className='text'>Reddit</span>
			</RedditShareButton>
		</div>
		<div className='button is-outlined is-rounded facebook'>
			<FacebookShareButton url={socialConfig.config.url}  >
				<span className='icon'>
					<FaFacebookF />
				</span>
				<span className='text'>Facebook</span>
			</FacebookShareButton>
		</div>
		<div className='button is-outlined is-rounded twitter'>
			<TwitterShareButton url={socialConfig.config.url} title={socialConfig.config.title} via={socialConfig.twitterHandle.split("@").join("")} >
				<span className='icon'>
					<FaTwitter />
				</span>
				<span className='text'>Twitter</span>
			</TwitterShareButton>
		</div>
		<div className='button is-outlined is-rounded whatsapp'>
			<WhatsappShareButton url={socialConfig.config.url}  title={socialConfig.config.title} >
				<span className='icon'>
					<FaWhatsapp />
				</span>
				<span className='text'>WhatsApp</span>
			</WhatsappShareButton>
		</div>
	</div>
  </>
)

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired
		})
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string)
}
Share.defaultProps = {
	tags: []
}

export default Share
